import { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router';

import Loading from 'common/components/loading';

const AgencyPage = lazy(() => import('./agency'));
const CollegeAdminPage = lazy(() => import('./student/college-admin'));
const SuperAdminPage = lazy(() => import('./super-admin'));
const StudentPage = lazy(() => import('./student'));
const ForgotMyPasswordPage = lazy(() => import('./forgotMyPassword.page'));
const ResendActivationPage = lazy(() => import('./resendActivation.page'));
const ResetMyPasswordPage = lazy(() => import('./resetMyPassword.page'));
const ActivationPage = lazy(() => import('./activation.page'));
const PaymentWaivedPage = lazy(() => import('./paymentWaived.page'));
const NoMatchPage = lazy(() => import('./noMatch.page'));
const TermsOfUsePage = lazy(() => import('./termsOfUse.page'));
const PrivacyPolicyPage = lazy(() => import('./privacyPolicy.page'));

const routes = (
  <Suspense
    fallback={
      <div className="flex h-screen items-center justify-center">
        <Loading />
      </div>
    }
  >
    <Switch>
      <Route path="/" exact component={AgencyPage} />
      <Route path="/terms-of-use" component={TermsOfUsePage} />
      <Route path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route path="/agency" component={AgencyPage} />
      <Route path="/admin" component={CollegeAdminPage} />
      <Route path="/super-admin" component={SuperAdminPage} />
      <Route path="/student" component={StudentPage} />
      <Route path="/forgot-my-password" component={ForgotMyPasswordPage} />
      <Route path="/resend-activation" component={ResendActivationPage} />
      <Route path="/recover" component={ResetMyPasswordPage} />
      <Route path="/confirm" component={ActivationPage} />
      <Route path="/payment-waived" component={PaymentWaivedPage} />
      <Route component={NoMatchPage} />
    </Switch>
  </Suspense>
);

export default routes;
